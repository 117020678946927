<template>
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-12 card p-1">
      <div>
        <div class="title head">
          <p class="h2" style="color: #558cef">ทรัพย์สินคงคลัง</p>
        </div>
        <hr />
        <!-- search input -->
        <div class="custom-search row justify-content-end align-items-center">
          <!-- <div class="col-12 col-md-4 mb-1">
            <v-select v-model="workG" label="title" :options="workGs" />
          </div> -->
          <b-form-group class="col-12 col-md-6">
            <div class="d-flex align-items-center">
              <label class="mr-1">ค้นหา</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="ค้นหา"
                type="text"
                class="d-inline-block"
              />
              <div>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <b-button variant="outline-primary"
                      ><feather-icon icon="ShareIcon" class="mr-50" />Export
                      <feather-icon icon="ChevronDownIcon" class="mr-50"
                    /></b-button>
                  </template>
                 
                  <b-dropdown-item @click="exportExcel">
                    <span>
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Excel
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon icon="ClipboardIcon" class="mr-50" />
                    <span>Pdf</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <b-overlay :show="show" rounded="sm">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            :line-numbers="true"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->
              <!-- <span
                v-if="props.column.field === 'fullName'"
                class="text-nowrap"
              >
                <b-avatar :src="props.row.avatar" class="mx-1" />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </span> -->

              <!-- <span v-else-if="props.column.field === 'จำนวน'">
                <div class="text-right">{{ props.row.จำนวน }}</div>
              </span>
              <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
                <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
              </span> -->

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click="dateField(props)"
                  >
                    <feather-icon icon="EyeIcon" size="16" class="mx-1" />
                  </b-button>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '10']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">of {{ props.total }} entries</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import vSelect from 'vue-select';
import store from '@/store/index';
import axios from 'axios';
import API from '@/views/connectDB/condb.js';
import { saveExcel } from '@progress/kendo-vue-excel-export';

export default {
  components: {
    vSelect,
    BButton,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },

  data() {
    return {
      show: false,
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'หมายเลขทะเบียน',
          field: 'equipmentgroup',
        },
        {
          label: 'ชื่อทะเบียน',
          field: 'equipmentname',
        },

        {
          label: 'รับเข้า',
          field: 'treasury',
        },
        {
          label: 'เบิก',
          field: 'withdraw',
          thClass: 'text-center',
        },

        {
          label: 'ยืม',
          field: 'borrow',
        },
         {
          label: 'คงคลัง',
          field: 'remaining',
        },
        
        // {
        //   field: 'action',
        // },
      ],
      rows: [],
      searchTerm: '',
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    this.gettable();
  },
  methods: {
    formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    dateField: async function (props) {
      this.$router.push({
        name: 'tableinkuruplun',
        params: {
          rowData: props.row, // or anything you want
        },
      });
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: 'ทรัพย์สินคงคลัง',
        columns: [
          {
            title: 'ทรัพย์สินคงคลัง',
            headerCellOptions: { textAlign: 'center' },
            children: [
              {
                title: 'วันที่รับเข้า',
                field: 'admitdate',
              },
              {
                title: 'เลขทะเบียน',
                field: 'equipment_code',
              },

              {
                title: 'ปีงบประมาณ',
                field: 'budget_year',
              },
              {
                title: 'ชื่อทรัพย์สิน',
                field: 'e_name',
                thClass: 'text-center',
              },

              {
                title: 'หน่วยนับ',
                field: 'unittype',
              },
              {
                title: 'ราคาต่อหน่วย',
                field: 'unitprice',
                type: 'number',
              },
              {
                title: 'ราคาต่อหน่วย + vat 7%',
                field: 'unitprice_vat',
                type: 'number',
              },
              {
                title: 'จำนวน',
                field: 'amount',
              },
            ],
          },
        ],
      });
    },

    async gettable() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}equipmentInStore?_sort=-1`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      // console.log(res.data.message.data);
      this.rows = res.data.message.data;
      this.show = false;
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization:
              localStorage.getItem('storedData') &&
              JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
